import React from "react";
import Header from "./components/header";
import { useState } from "react";
import { Route, Routes } from "react-router-dom";
import Home from "./components/Home/home";
import Preload from "./components/Home/preload";
import Footer from "./components/Home/footer";
import Contact from "./components/Contact/contact";
import Schedule from "./components/Schedule/schedule";
import Financing from "./components/Finance/financing";
import StudentWall from "./components/StudentWall/studentwall";
import Courses from "./components/Courses/courses";
import Quiz from "./components/Quiz/quiz";
import LCVTraining from "./components/LCVTraining/lcvtraining";
import CourseDetails from "./components/CourseDetails/coursedetails";

function App() {
  const [isLoading] = useState(false);

  //   const handleLoading = () => {
  //     setIsLoading(false);
  //   };

  //   useEffect(() => {
  //     setTimeout(() => {
  //       handleLoading();
  //     }, 10);
  //     // window.addEventListener('load', handleLoading);
  //     // return () => window.removeEventListener('load', handleLoading);
  //   }, []);

  return (
    <div className="App">
      {isLoading ? (
        <Preload />
      ) : (
        <>
          <Header />
          <Routes>
            <Route
              path="/"
              element={
                <React.Suspense fallback={<Preload />}>
                  <Home />
                </React.Suspense>
              }
            />
            <Route path="/contact" element={<Contact />} />
            <Route path="/schedule" element={<Schedule />} />
            <Route path="/financing" element={<Financing />} />
            <Route path="/studentwall" element={<StudentWall />} />
            <Route path="/courses" element={<Courses />} />
            <Route path="/coursedetails" element={<CourseDetails />} />
            <Route path="/lcvtraining" element={<LCVTraining />} />
            <Route
              path="/abQuiz"
              element={<Quiz quizFor={"air-brake-quiz"} />}
            />
            <Route
              path="/kbQuiz"
              element={<Quiz quizFor={"knowledge-quiz"} />}
            />
          </Routes>
          <Footer />
        </>
      )}
    </div>
  );
}

export default App;
